@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

.team-block__team_list {
	
	&.detail {
		background-color: var(--wp--preset--color--bnpp-green-tint-10);
		padding: var(--wp--preset--spacing--60);


		.team-table {
	
			margin-top: var(--wp--preset--spacing--40);

			h4 {
				font-weight: 400;
			}
			
			h4, p, a {
				margin: 0;
				font-size: var(--wp--preset--font-size--heading-xs);
			}

			p, a {
				font-family: var(--wp--preset--font-family--bnpp-sans-condensed);
				font-weight: 300;
			}

			a {

				color: var(--wp--preset--color--bnpp-black);
				text-decoration-thickness: 1px;

				&::after {
					content: "\2192";
					font-family: system-ui;
				}
			}
	
			.row {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				align-items: center;
				padding: var(--wp--preset--spacing--30) 0;
				gap: var(--wp--preset--spacing--30);

				@media only screen and (max-width: bp.$breakpoint-small) {
					grid-template-rows: auto;
        			grid-template-columns: 1fr 1fr;
					gap: 0;

					&.team-row {
						p:nth-child(2) {
							grid-row: 2;
						}

						p:nth-child(3), a {
							grid-row: 3;
						}
					}
				}

				@media only screen and (max-width: bp.$breakpoint-tiny) {
					grid-template-columns: 1fr;

					&.team-row > a {
						grid-row: auto;
					}
				}

				&.header-row {

					@media only screen and (max-width: bp.$breakpoint-small) {
						grid-template-columns: 1fr;
					}
					
					div {
						color: var(--wp--preset--color--bnpp-green);
					}
				}

			}
	
		}

		&.dark {
			border: solid 1px #7DCBBA;

			a {
				color: var(--wp--preset--color--white);
			}

			.row {

				&.header-row {
					div {
						color: #7DCBBA;
					}
				}

			}
		}

	}

	&.grid {
		@include mixins.grid(275px, auto-fill);

		.person {
			background-color: var(--wp--preset--color--bnpp-green-tint-10);
			display: flex;
			flex-wrap: wrap;
			height: fit-content;
			gap: var(--wp--preset--spacing--60);
			padding: var(--wp--preset--spacing--60);
			height: 100%;

			.post-thumbnail {

				img {
					--profileImageSize: 85px;
					border-radius: 50%;
					width: var(--profileImageSize);
					height: var(--profileImageSize);
					object-fit: cover;
				}

			}

			.post-content {

				flex: 1 1 100px;

				h3 {
					font-size: var(--wp--preset--font-size--heading-s);
				}

				p {
					font-weight: 300;
					margin: 0;
				}

			}
		}
	}

}